import React, { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Istate, setActiveItem } from "../../redux";

import "./Inventory.scss";
import InventoryEditorContainer from "./InventoryEditorContainer";
import {
  handleInventorySearch,
  updateActiveItem,
} from "../../redux/inventory/inventoryActions";
import { ToastyRed } from "../constants";
export { InventoryListContainer } from "./InventoryListContainer";

interface IpropsInventory {}
export const Inventory: React.FunctionComponent<IpropsInventory> = () => {
  const dispatch = useDispatch();
  // if the user clicked on an item this will not be undefined
  const { itemNo }: { itemNo: string | undefined } = useParams();
  const pathName = useLocation().pathname;
  const editingExistingItem = pathName.includes("edit");
  // the item that was selected
  const item: itemVerbose | undefined = useSelector(
    (state: Istate) =>
      itemNo && editingExistingItem
        ? state.data.inventory.find((item) => item.itemNo === itemNo)
        : undefined,
    shallowEqual
  );
  const socket = useSelector(
    (state: Istate) => state.data.socket,
    shallowEqual
  );

  const socketUpdateItem = (item: string) => {
    let itemInformation = JSON.parse(item).itemInformation;
    dispatch(updateActiveItem(itemInformation));
    if (itemInformation.itemNo === itemNo)
      ToastyRed(`Item ${itemInformation.itemNo} was updated.`);
    dispatch(handleInventorySearch({}));
  };

  useEffect(() => {
    if (socket) socket.on("INVENTORY_PATCH", socketUpdateItem);
    return () => {
      if (socket) socket.off("INVENTORY_PATCH", socketUpdateItem);
    };
  }, [socket]);

  useEffect(() => {
    if (socket) {
      socket.off("INVENTORY_PATCH", socketUpdateItem);
      socket.on("INVENTORY_PATCH", socketUpdateItem);
    }
    return () => {
      if (socket) socket.off("INVENTORY_PATCH", socketUpdateItem);
    };
  }, [itemNo]);

  useEffect(() => {
    item && editingExistingItem && dispatch(setActiveItem(item));
  }, [itemNo, item, dispatch]);

  return <InventoryEditorContainer />;
};
