import { useEffect, useState, FC, useMemo } from "react";
import styled from "styled-components";
import InventoryListHeader from "./InventoryListHeader";
import InventoryListBody from "./InventoryListBody";
import { InventoryHeader } from "./InventoryHeader/";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector, shallowEqual } from "react-redux";
import { Istate } from "../../redux";
const InventoryListWrapper = styled.div`
  border-radius: 2px;
  min-height: 100px;
  overflow-y: hidden;
  height: inherit;
`;

interface IpropsInventoryListContainer {}
export const InventoryListContainer: FC<IpropsInventoryListContainer> = (
  props
) => {
  const [openPrint, setOpenPrint] = useState(false);
  const [sort, setSort] = useState<{
    sort: "asc" | "desc";
    column: keyof itemVerbose;
  }>({ sort: "asc", column: "" });

  const filteredInventory = useSelector(
    ({ data: { filteredInventory } }: Istate) => filteredInventory,
    shallowEqual
  );
  const allItemsAsObject = useMemo(() => {
    return filteredInventory.reduce((acc, item) => {
      acc[item.itemID] = item;
      return acc;
    }, {} as Record<number, itemVerbose>);
  }, [filteredInventory]);

  const sortedItems = useMemo(() => {
    return filteredInventory.sort((a, b) => {
      //string sort
      // if (sort.sort === "asc")
      //   return ("" + a[sort.column]).localeCompare("" + b[sort.column]);
      // return ("" + b[sort.column]).localeCompare("" + a[sort.column]);
      if (sort.sort === "asc")
        return Number(a[sort.column]) - Number(b[sort.column]);
      return Number(b[sort.column]) - Number(a[sort.column]);
    });
  }, [allItemsAsObject, filteredInventory, sort.column, sort.sort]);

  const filteredInventoryMemo = useMemo(
    () => Array.from(new Set(sortedItems.map(({ itemID }) => itemID))),
    [sortedItems, sort.column, sort.sort]
  );

  const { length: filteredInventoryLength } = filteredInventoryMemo;
  const [moreItems, setMoreItems] = useState(filteredInventoryLength > 0);

  const [itemsToRender, setItemsToRender] = useState<
    typeof filteredInventoryMemo
  >([]);
  // if there ARE items, more items is true
  const loadMoreItems = () => {
    const numberOfItemsToEndOn =
      itemsToRender.length + 15 < filteredInventoryLength
        ? itemsToRender.length + 15
        : filteredInventoryLength;
    setMoreItems(!(numberOfItemsToEndOn === filteredInventoryLength));
    setItemsToRender(filteredInventoryMemo.slice(0, numberOfItemsToEndOn));
  };

  useEffect(() => {
    // if it is empty add 15 or as many as posible
    if (itemsToRender.length === 0 && filteredInventoryLength > 0) {
      const sliceNumber =
        filteredInventoryLength > 15 ? 15 : filteredInventoryLength;
      setItemsToRender(filteredInventoryMemo.slice(0, sliceNumber));
      setMoreItems(filteredInventoryLength > sliceNumber);
    }
  }, [filteredInventoryMemo, filteredInventoryLength, itemsToRender]);

  useEffect(() => {
    const sliceNumber =
      filteredInventoryLength > 15 ? 15 : filteredInventoryLength;
    setItemsToRender(filteredInventoryMemo.slice(0, sliceNumber));
    setMoreItems(filteredInventoryLength > sliceNumber);
  }, [filteredInventoryMemo, filteredInventoryLength]);

  const [offsetHeight, setOffsetHeight] = useState<number | undefined>(
    undefined
  );
  console.log(sort);
  return (
    <div
      className="p-1 overflow-y-hidden"
      style={{ maxHeight: "97vh", height: "97vh" }}
    >
      <InventoryListWrapper className="relative">
        <InventoryHeader
          setOffsetHeight={setOffsetHeight}
          openPrint={openPrint}
          setOpenPrint={setOpenPrint}
        />
        <InfiniteScroll
          dataLength={itemsToRender.length}
          next={loadMoreItems}
          hasMore={moreItems}
          scrollableTarget="item-scroll-target"
          loader={<></>}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>No More Items Try Searching for Something Else</b>
            </p>
          }
        >
          <table className="inventory-table mt-1">
            <thead>
              <InventoryListHeader sort={sort} setSort={setSort} />
            </thead>
            <tbody
              style={{
                height: `calc(97vh - ${offsetHeight || 0}px - 2rem)`,
              }}
              id="item-scroll-target"
            >
              <InventoryListBody itemsToRender={itemsToRender} />
            </tbody>
          </table>
        </InfiniteScroll>
      </InventoryListWrapper>
    </div>
  );
};
