import React from "react";

interface Props {
  setSort: React.Dispatch<
    React.SetStateAction<{
      sort: "asc" | "desc";
      column: keyof itemVerbose;
    }>
  >;
  sort: {
    sort: "asc" | "desc";
    column: keyof itemVerbose;
  };
}
function InventoryListHeader({ setSort, sort }: Props) {
  return (
    <tr className="border-b border-gray-300" style={{ lineHeight: "14px" }}>
      <th>Picture</th>
      <th
        className="cursor-pointer"
        onClick={() => {
          setSort((current) => {
            if (current.column === "itemID") {
              return {
                sort: current.sort === "asc" ? "desc" : "asc",
                column: "itemID",
              };
            }
            return { sort: "asc", column: "itemID" };
          });
        }}
      >
        Item #
      </th>
      <th>Quantity</th>
      <th>Grouped</th>
      <th
        className="cursor-pointer"
        onClick={() => {
          if (sort.column === "rentalPrice") {
            setSort({
              sort: sort.sort === "asc" ? "desc" : "asc",
              column: "rentalPrice",
            });
            return;
          }
          setSort({ sort: "asc", column: "rentalPrice" });
        }}
      >
        Retail
      </th>
      <th
        className="cursor-pointer"
        onClick={() => {
          if (sort.column === "rentalRate") {
            setSort({
              sort: sort.sort === "asc" ? "desc" : "asc",
              column: "rentalRate",
            });
            return;
          }
          setSort({ sort: "asc", column: "rentalRate" });
        }}
      >
        Rate
      </th>
      <th>item Name</th>
    </tr>
  );
}

export default React.memo(InventoryListHeader);
